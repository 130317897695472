<template>
  <div class="text-center">
    <transition name="slide-fade-color-bdgd">
      <v-snackbar
        v-if="snackbar"
        v-model="snackbar"
        right
        :timeout="timeout"
        :style="getCssVars"
        class="snack-small background-infinity-color-bdgd"
      >
        <span
          class="color-text-infinity-color-bdgd"
          v-html="rawHtmlText"
        />
        <v-btn
          color="black"
          class="mx-4"
          height="35"
          width="100"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </v-snackbar>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CorBdgdInfo',
  data: () => ({
    snackbar: false,
    timeout: 1000 * 60 * 24 // (24 horas)
  }),
  created() {
    if (this.exibeAlerta) this.snackbar = true;
  },
  computed: {
    userSelectedCompanyBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    },
    userSelectedCompanyBdgdVersionTxt() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersionTxt;
    },
    companyAcronym() {
      return this.$store.getters.getCompanyAcronym;
    },
    ambiente() {
      return this.translate(this.$store.getters.getAmbiente);
    },
    isProducao() {
      return !!(this.ambiente == 'produção');
    },
    exibeAlerta() {
      return this.isProducao && this.userSelectedCompanyBdgdVersion.cor;
    },
    rawHtmlText() {
      return this.exibeAlerta
        ? `<b>Atenção: você está conectado na ${this.companyAcronym} :: ${this.userSelectedCompanyBdgdVersionTxt}</b>`
        : '';
    },
    getCssVars() {
      return {
        '--colorBdgd': this.userSelectedCompanyBdgdVersion.cor
      };
    }
  },
  methods: {
    translate(ambiente) {
      if (!ambiente) return null;
      switch (ambiente.toLowerCase()) {
        case 'staging':
          return 'homologação';
        case 'local':
          return 'desenvolvimento';
        case 'production':
          return 'produção';
        default:
          return null;
      }
    }
  }
};
</script>

<style>
.slide-fade-color-bdgd-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-color-bdgd-enter,
.slide-fade-color-bdgd-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
.snack-small .v-snack__content {
  padding: 0 10px !important;
}
.snack-bg-primary {
  padding: 3px !important;
  border-radius: 50px !important;
}
.background-infinity-color-bdgd .v-snack__content {
  animation: changeBackgroundColorBdgd 7s infinite;
}
.color-text-infinity-color-bdgd {
  animation: changeTextColorBdgd 7s infinite;
}
@keyframes changeBackgroundColorBdgd {
  0% {
    background-color: #001f3f;
  }
  50% {
    background-color: var(--colorBdgd);
  }
  100% {
    background-color: #001f3f;
  }
}
@keyframes changeTextColorBdgd {
  0% {
    color: black;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #fff;
  }
}
</style>
